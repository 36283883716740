<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">订单查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item
              class="flight-city-form-item"
              label="首航段"
              label-align="left"
            >
              <FlightCityGroupSelector
                ref="citySelector"
                :depCity="cities.depCity"
                :arrCity="cities.arrCity"
                @depChange="onDepChange"
                @arrChange="onArrChange"
              />
            </a-form-item>
            <a-form-item>
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.dateType',
                  {
                    initialValue: '0',
                  },
                ]"
              >
                <a-select-option value="0">起飞日期</a-select-option>
                <a-select-option value="1">申请日期</a-select-option>
                <a-select-option value="2">成交日期</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                size="small"
                v-decorator="['searchForm.rangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="订单号"
                v-decorator="[
                  'searchForm.orderNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="PNR"
                v-decorator="[
                  'searchForm.pnr',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="航班号"
                v-decorator="[
                  'searchForm.flightNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                :style="{ width: '100px' }"
                size="small"
                placeholder="issCode"
                v-decorator="[
                  'searchForm.issCode',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="票号"
                v-decorator="[
                  'searchForm.ticketNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
            <!-- <a-form-item>
              <a-input
                size="small"
                placeholder="银行订单号"
                v-decorator="[
                  'searchForm.platformTradeNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              >
              </a-input>
            </a-form-item> -->
            <a-form-item>
              <a-select
                size="small"
                style="width: 150px"
                v-decorator="[
                  'searchForm.idType',
                  {
                    initialValue: '0',
                  },
                ]"
              >
                <a-select-option value="0">旅客身份证号</a-select-option>
                <a-select-option value="1">旅客姓名</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="旅客信息"
                v-decorator="[
                  'searchForm.certInfo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="订单状态">
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.orderStatus',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option v-for="(status) in orderStatus" :key="status.value" :value="status.value">
                  {{ status.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach">
                查询
              </a-button>
              <a-button size="small" type="primary" ghost @click="onReset">
                重置
              </a-button>
              <!-- <a-button size="small" type="info"> 导出 </a-button> -->
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{x: 'max-content'}"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            :loading="loading"
            @change="onTableChange"
          >
            <template slot="index" slot-scope="index">
              {{ index  }}
            </template>
            <template slot="orderNo" slot-scope="orderNo">
              <router-link
                :to="{
                  path: '/orderManager/orderDetail',
                  query: { id: orderNo },
                }"
                tag="a"
                target="_blank"
                >{{ orderNo }}</router-link
              >
            </template>
            <template slot="segments" slot-scope="segments">
              <div v-for="(item, idx) in segments" :key="idx">
                {{ item.orgCity }}-{{ item.dstCity }}
              </div>
            </template>
            <template slot="createdTime" slot-scope="createdTime">
              <div>{{ createdTime }}</div>
            </template>
            <template slot="flightNo" slot-scope="flightNo">
              <div v-for="(item, idx) in flightNo" :key="idx">{{ item }}</div>
            </template>
            <template slot="flightDate" slot-scope="flightDate">
              <div v-for="(item, idx) in flightDate" :key="idx">{{ item }}</div>
            </template>
            <template slot="pnrs" slot-scope="pnrs">
              <span v-for="(pnr) in pnrs" :key="pnr">{{pnr}}<br/></span>
            </template>
            <template slot="orderStatus" slot-scope="orderStatus">
              <div>{{ orderStatus | status }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'
// import { validate } from '@/utils/validate'

import { airCity } from '@/filters/flight'

import FlightCityGroupSelector from '@/components/FightCityGroupSelector/FlightCityGroupSelector'

import { fetchOrderList } from '@/api/order'
import { status } from '@/filters/order'
import { status as orderStatus } from '@/dicts/order'

import _ from 'lodash'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '订单号',
    dataIndex: 'orderNo',
    scopedSlots: { customRender: 'orderNo' }
  },
  {
    title: '航段',
    dataIndex: 'segments',
    scopedSlots: { customRender: 'segments' }
  },
  {
    title: '提交时间',
    dataIndex: 'createdTime',
    scopedSlots: { customRender: 'createdTime' }
  },
  {
    title: '航班号',
    dataIndex: 'flightNo',
    scopedSlots: { customRender: 'flightNo' }
  },
  {
    title: '航班日期',
    dataIndex: 'flightDate',
    scopedSlots: { customRender: 'flightDate' }
  },
  {
    title: '数量',
    dataIndex: 'leftGroupQty'
  },
  {
    title: '单价',
    dataIndex: 'pirce'
  },
  {
    title: '总价',
    dataIndex: 'orderAmount'
  },
  {
    title: '出票时限',
    dataIndex: 'timeLimit'
  },
  {
    title: 'PNR',
    dataIndex: 'pnrs',
    scopedSlots: { customRender: 'pnrs' }
  },
  {
    title: '状态',
    dataIndex: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  }
]

function dataParse (content, params) {
  const { page, size } = params

  return content.map((col, index) => {
    const segments = col.segmentList && col.segmentList.map((seg) => {
      const { orgCity, dstCity } = seg
      return { orgCity: airCity(orgCity), dstCity: airCity(dstCity) }
    })

    const flightNo = col.segmentList && col.segmentList.map((seg) => {
      const { flightNo } = seg
      return flightNo
    })

    const departureDate = col.segmentList && col.segmentList.map((seg) => {
      const { departureDate } = seg
      return departureDate
    })

    return {
      key: col.orderNo,
      index: page * size + (index + 1),
      ...col,
      ...{
        segments: segments,
        flightNo: flightNo,
        flightDate: departureDate,
        pirce: _.sumBy(col.segmentList, (o) => { return o.netFare }) || '',
        orderStatus: col.orderStatus
      }
    }
  })
}

export default {
  name: 'orderSearch',
  components: {
    FlightCityGroupSelector
  },
  mixins: [tableBaseMixin, orderSearchMixin],
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      orderStatus,
      columns,
      cities: {
        depCity: '',
        arrCity: ''
      },
      params: {
        depCode: '',
        arrCode: '',
        bookingDateEnd: '',
        bookingDateStart: '',
        certID: '',
        contactMobile: '',
        departureDateEnd: '',
        departureDateStart: '',
        flightNo: '',
        issCode: '',
        orderNo: '',
        orderStatus: '',
        // platformTradeNo: '',
        passengerName: '',
        pnr: '',
        ticketNo: '',
        page: 0,
        size: 10
      }
    }
  },
  mounted () {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData () {
      this.loading = true
      try {
        const result = await fetchOrderList(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        this.tableData = dataParse(content, this.params)

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm
      const { cities } = this

      console.log(fields)

      const params = {
        firstSegDepCode: cities.depCity,
        firstSegArrCode: cities.arrCity,
        flightNo: fields.flightNo,
        issCode: fields.issCode,
        orderNo: fields.orderNo,
        orderStatus: fields.orderStatus,
        // platformTradeNo: fields.platformTradeNo,
        pnr: fields.pnr,
        ticketNo: fields.ticketNo
      }

      const dateTypeTimeMap = {
        0: {},
        1: {},
        2: {}
      }

      if (fields.rangeTime) {
        dateTypeTimeMap[fields.dateType] = {
          start: fields.rangeTime[0] ? fields.rangeTime[0].format('YYYY-MM-DD 00:00:00') : '',
          end: fields.rangeTime[1] ? fields.rangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
        }

        params.departureDateStart = dateTypeTimeMap[0].start
        params.departureDateEnd = dateTypeTimeMap[0].end

        params.bookingDateStart = dateTypeTimeMap[1].start
        params.bookingDateEnd = dateTypeTimeMap[1].end

        params.dealTimeStart = dateTypeTimeMap[2].start
        params.dealTimeEnd = dateTypeTimeMap[2].end
      }

      const idTypeMap = {
        0: '',
        1: ''
      }

      idTypeMap[fields.idType] = fields.certInfo

      params.certID = idTypeMap[0]
      params.passengerName = idTypeMap[1]

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchTableData()
    },
    handleFieldValidate (rule, value, callback) {
      // validate(
      //   'search-form',
      //   this.searchForm.getFieldsValue(),
      //   rule.fullField
      // ).then(({ valid, error }) => {
      //   if (valid) {
      //     callback()
      //   } else {
      //     callback(error)
      //   }
      // })
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchTableData()
    },
    onReset () {
      this.$refs.citySelector.reset()
      this.searchForm.resetFields()
    },
    onDepChange (city) {
      this.cities.depCity = city
    },
    onArrChange (city) {
      this.cities.arrCity = city
    }
  },
  filters: {
    status
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
