var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-config-provider',{attrs:{"locale":_vm.local}},[_c('div',{staticClass:"order-wrap"},[_c('div',{staticClass:"order-title"},[_vm._v("订单查询")]),_c('div',{staticClass:"order-main"},[_c('div',{staticClass:"order-form"},[_c('a-form',{attrs:{"form":_vm.searchForm,"layout":"inline"}},[_c('a-form-item',{staticClass:"flight-city-form-item",attrs:{"label":"首航段","label-align":"left"}},[_c('FlightCityGroupSelector',{ref:"citySelector",attrs:{"depCity":_vm.cities.depCity,"arrCity":_vm.cities.arrCity},on:{"depChange":_vm.onDepChange,"arrChange":_vm.onArrChange}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.dateType',
                {
                  initialValue: '0',
                } ]),expression:"[\n                'searchForm.dateType',\n                {\n                  initialValue: '0',\n                },\n              ]"}],staticStyle:{"width":"100px"},attrs:{"size":"small"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("起飞日期")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("申请日期")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("成交日期")])],1)],1),_c('a-form-item',[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['searchForm.rangeTime']),expression:"['searchForm.rangeTime']"}],attrs:{"size":"small","show-time":"","format":"YYYY-MM-DD"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.orderNo',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'searchForm.orderNo',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                },\n              ]"}],attrs:{"size":"small","placeholder":"订单号"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.pnr',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'searchForm.pnr',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                },\n              ]"}],attrs:{"size":"small","placeholder":"PNR"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.flightNo',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'searchForm.flightNo',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                },\n              ]"}],attrs:{"size":"small","placeholder":"航班号"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.issCode',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'searchForm.issCode',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                },\n              ]"}],style:({ width: '100px' }),attrs:{"size":"small","placeholder":"issCode"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.ticketNo',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'searchForm.ticketNo',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                },\n              ]"}],attrs:{"size":"small","placeholder":"票号"}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.idType',
                {
                  initialValue: '0',
                } ]),expression:"[\n                'searchForm.idType',\n                {\n                  initialValue: '0',\n                },\n              ]"}],staticStyle:{"width":"150px"},attrs:{"size":"small"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("旅客身份证号")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("旅客姓名")])],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.certInfo',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'searchForm.certInfo',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                },\n              ]"}],attrs:{"size":"small","placeholder":"旅客信息"}})],1),_c('a-form-item',{attrs:{"label":"订单状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.orderStatus',
                {
                  initialValue: '',
                } ]),expression:"[\n                'searchForm.orderStatus',\n                {\n                  initialValue: '',\n                },\n              ]"}],staticStyle:{"width":"100px"},attrs:{"size":"small"}},_vm._l((_vm.orderStatus),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(" "+_vm._s(status.label)+" ")])}),1)],1),_c('a-form-item',[_c('a-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSeach}},[_vm._v(" 查询 ")]),_c('a-button',{attrs:{"size":"small","type":"primary","ghost":""},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{staticClass:"order-table"},[_c('a-table',{attrs:{"scroll":{x: 'max-content'},"columns":_vm.columns,"data-source":_vm.tableData,"pagination":_vm.pageOptions,"rowKey":function (record, index) {
              return index;
            },"bordered":"","loading":_vm.loading},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"index",fn:function(index){return [_vm._v(" "+_vm._s(index)+" ")]}},{key:"orderNo",fn:function(orderNo){return [_c('router-link',{attrs:{"to":{
                path: '/orderManager/orderDetail',
                query: { id: orderNo },
              },"tag":"a","target":"_blank"}},[_vm._v(_vm._s(orderNo))])]}},{key:"segments",fn:function(segments){return _vm._l((segments),function(item,idx){return _c('div',{key:idx},[_vm._v(" "+_vm._s(item.orgCity)+"-"+_vm._s(item.dstCity)+" ")])})}},{key:"createdTime",fn:function(createdTime){return [_c('div',[_vm._v(_vm._s(createdTime))])]}},{key:"flightNo",fn:function(flightNo){return _vm._l((flightNo),function(item,idx){return _c('div',{key:idx},[_vm._v(_vm._s(item))])})}},{key:"flightDate",fn:function(flightDate){return _vm._l((flightDate),function(item,idx){return _c('div',{key:idx},[_vm._v(_vm._s(item))])})}},{key:"pnrs",fn:function(pnrs){return _vm._l((pnrs),function(pnr){return _c('span',{key:pnr},[_vm._v(_vm._s(pnr)),_c('br')])})}},{key:"orderStatus",fn:function(orderStatus){return [_c('div',[_vm._v(_vm._s(_vm._f("status")(orderStatus)))])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }